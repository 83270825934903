<script setup>
import {Link} from '@inertiajs/vue3';
import AkLogoSmall from "@/Components/Icons/AkLogoSmall.vue";
import {globe, logoVk} from "ionicons/icons";
import {IonIcon} from "@ionic/vue";
import TelegramLogo from "@/Components/Icons/TelegramLogo.vue";
import route from "ziggy-js";
import {getCurrencies, getLangs, HelpDeskUrl, tgSupportUrl} from "@/constants.js";
import Arrow from "@/Components/Icons/Arrow.vue";
import DropdownLink from "@/Components/DropdownLink.vue";
import Dropdown from "@/Components/Dropdown.vue";
import {ref} from "vue";
import Rubel from "@/Components/Icons/Rubel.vue";
import SelectInputNotBorder from "@/Components/SelectInputNotBorder.vue";
import {storeToRefs} from "pinia";
import {useUser} from "@/Store/User.js";
import Usd from "@/Components/Icons/Usd.vue";
import {activeCurrency, onSelectCurrency, onSelectLang} from "@/helper.js";
import MyWot from "@/Components/Icons/MyWot.vue";

const statusLang = ref(false),
    statusCurrency = ref(false),
    storeUser = useUser(),
    {lang} = storeToRefs(storeUser),
    listCurrency = getCurrencies()
const currency = ref(activeCurrency)

</script>

<template>
    <div class="bg-white mt-6 w-full">
        <div class="max-w-7xl mx-auto">
            <div class="flex justify-between items-center">
                <div class="flex flex-col lg:flex-row">
                    <Link
                        :href="route('pages.show', {slug:'about'})"
                        class="px-3 py-2 font-condensed text-sm font-semibold text-deep-blue hover:text-blue-600 transition-colors">
                        {{ $t('About AnyKod') }}
                    </Link>
                    <a class="px-3 py-2 font-condensed text-sm font-semibold  text-deep-blue hover:text-blue-600 transition-colors"
                       :href="HelpDeskUrl" target="_blank">{{ $t('Help Center') }}</a>
                    <Link
                        :href="route('pages.show', {slug:'vacancies'})"
                        class="px-3 py-2 font-condensed text-sm font-semibold text-deep-blue hover:text-blue-600 transition-colors">
                        {{ $t('Vacancies') }}
                    </Link>
                </div>
                <a class="flex justify-center items-center py-2.5 px-4"
                   href="https://www.trustpilot.com/review/anykod.com"
                   target="_blank">
                    <MyWot />
                </a>
            </div>
            <div class="border-t border-slate-300 py-5 px-3">
                <div class="flex justify-between items-center flex-wrap">
                    <div class="flex gap-16 items-center">
                        <AkLogoSmall class="w-[100px] h-[16px]"></AkLogoSmall>
                        <div class="hidden lg:flex gap-2">
                            <Dropdown align="left"
                                      width="60" @close="statusLang = false" @open="statusLang = true">
                                <template #trigger>
                <span class="flex">
                    <button type="button" class="flex gap-1.5 items-center text-sm">
                        <ion-icon :icon="globe" class="text-xl  align-middle"></ion-icon>
                        {{ lang.name }}<arrow ref="arrow" class="w-3" :class="[statusLang ? 'rotate-180': 'rotate-0']"/>
                    </button>
                </span>
                                </template>
                                <template #content>
                                    <div class="">
                                        <!-- Team Management -->

                                        <template v-for="itemLang in getLangs()">
                                            <dropdown-link as="button" @click="onSelectLang(itemLang)">
                                                {{ itemLang.name }}
                                            </dropdown-link>
                                        </template>
                                    </div>
                                </template>
                            </Dropdown>
                            <Dropdown align="left"
                                      width="60" @close="statusCurrency = false" @open="statusCurrency = true">
                                <template #trigger>
                <span class="flex">
                    <button type="button" class="flex gap-1.5 items-center text-sm">
                        <Rubel v-if="activeCurrency?.code === 'RUB'"/>
                        <Usd v-else></Usd>
                        {{ activeCurrency?.code }}<arrow ref="arrow" class="w-3"
                                                  :class="[statusCurrency ? 'rotate-180': 'rotate-0']"/>
                    </button>
                </span>
                                </template>
                                <template #content>
                                    <div class="">
                                        <template v-for="itemCurrency in listCurrency">
                                            <dropdown-link as="button" @click="onSelectCurrency(itemCurrency)">
                                                {{ itemCurrency.code }}
                                            </dropdown-link>
                                        </template>
                                    </div>
                                </template>
                            </Dropdown>
                        </div>
                    </div>
                    <div class="flex justify-between items-center">
                        <a class="hover:text-deep-blue text-slate-500 transition-colors" href="https://vk.com/anykod"
                           target="_blank">
                            <ion-icon :icon="logoVk" class="text-2xl  align-middle"></ion-icon>
                        </a>
                        <a class="ml-3 group" :href="tgSupportUrl" target="_blank">
                            <TelegramLogo class="w-[24px] h-[24px] group-hover:fill-deep-blue transition-colors"/>
                        </a>
                        <!--                <NavLink class="ml-3 hover:text-deep-blue text-slate-500 transition-colors" href="#">-->
                        <!--                    <ion-icon :icon="logoYoutube" class="text-2xl  align-middle"></ion-icon>-->
                        <!--                </NavLink>-->
                        <!--                <NavLink class="ml-3 hover:text-deep-blue text-slate-500 transition-colors" href="#">-->
                        <!--                    <ion-icon :icon="logoFacebook" class="text-2xl  align-middle"></ion-icon>-->
                        <!--                </NavLink>-->
                    </div>
                    <div class="flex lg:hidden w-full mt-2.5 gap-2">

                        <div class="flex gap-2">
                            <ion-icon :icon="globe" class="text-xl  align-middle"></ion-icon>
                            <SelectInputNotBorder
                                :default-item="false"
                                :modelValue="lang"
                                :items="getLangs()"
                                @update:modelValue="onSelectLang($event)"
                                title-field="name"
                                class="font-normal !text-sm !w-20"
                            ></SelectInputNotBorder>
                        </div>

                        <div class="flex gap-2">
                            <Rubel v-if="activeCurrency?.code === 'RUB'"/>
                            <Usd v-else></Usd>
                            <SelectInputNotBorder
                                :default-item="false"
                                :modelValue="currency"
                                :items="listCurrency"
                                @update:modelValue="onSelectCurrency($event)"
                                title-field="code"
                                class="font-normal !text-sm !w-15"
                            ></SelectInputNotBorder>
                        </div>


                    </div>
                </div>
            </div>
            <div class="px-3 flex flex-wrap gap-x-6">
                <Link class="font-condensed text-slate-600 hover:text-black transition-colors font-medium text-sm"
                      :href="route('pages.show', {slug:'privacy-policy'})">{{ $t('Privacy Policy') }}
                </Link>
                <Link class="font-condensed text-slate-600 hover:text-black transition-colors font-medium text-sm"
                      :href="route('pages.show', {slug:'cookies-policy'})">{{ $t('Cookie Policy') }}
                </Link>
                <Link class="font-condensed text-slate-600 hover:text-black transition-colors font-medium text-sm"
                      :href="route('pages.show', {slug:'terms-of-use'})">{{ $t('Terms of Use') }}
                </Link>
            </div>
            <div class="px-3 pt-3 pb-5 text-deep-blue font-sans font-medium">
                © 2024 Anykod
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
